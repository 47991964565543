body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FlippinFont';
  src: url('./fonts/flippinfont.ttf') format('truetype');
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
}

body {
  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container {
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.MuiOutlinedInput-root {
  border-radius: 25px;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 25px;
}

.play-stop-button, .nav-button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.nav-button {
  width: 50px;
  height: 50px;
  font-size: 30px;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100px;
  margin-top:0 !important;
}

.favicon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.favicon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab-button{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.app-title {
  font-family: "FlippinFont", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem !important;
  position: fixed;
  top: 35px; 
  text-align: center;
  width: 100%;
}

/* Adjust font-size for different screen widths */
@media (min-width: 600px) {
  .app-title  {
    font-size: 2rem !important;
    top: 34px; 
  }
}
@media (min-width: 768px) {
  .app-title {
    font-size: 2.5rem !important;
    top: 35px; 
  }
}
@media (min-width: 1024px) {
  .app-title {
    font-size: 3rem !important;
    top: 35px; 
  }
}

.MuiDrawer-paper {
  max-height: 90% !important;
}

.MuiDrawer-root>.MuiPaper-root {
  height: 90% !important;
}

.puller {
  width: 30px;
  height: 6px;
  background-color: #ccc;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
}
.drawer-content {
  overflow-y: auto;
  height: 100%;
}
.gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.image-container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}
.image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
}
.image-title {
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.sticky-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding: 16px 0;
  width: 100%;
  box-shadow: 0 0px 5px rgba(0,0,0,0.12), 0 0px 1px rgba(0,0,0,0.24)
}
.sticky-header .title {
  margin-bottom: 16px;
}
.sticky-container {
  width: calc( 100% - 32px );
  text-align: center;
  margin: auto;
}
.title {
  padding-top: 20px;
  text-align: center;
}
.loader {
  border: 4px solid;
  border-radius: 50%;
  border-top: 4px solid;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}